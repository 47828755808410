
import { userDetectedEvent, pageInitializedEvent, pageLoadCompletedEvent, pageInfoEvent } from '@chr/react-analytics';

const isUserInternal = (email) => {
    if (!email) return true;
    const internalEmail = ["@mytmc.com", "@chrobinson.com"];
    return internalEmail.some(el => email.includes(el.toLowerCase()));
}

const trackUserLoginSuccess = (track, userId, tenantId, email, name) => {
    track(pageInitializedEvent());
    track(
        {
            event: "userLoginSuccess",
            user: {
                userId: userId,
                tenantId: tenantId,
                name: name,
                internalUser: `${isUserInternal(email)}`,
                email: email
            }
        }
    );
    track(
        userDetectedEvent({
            id: userId,
            tenantId: tenantId,
            internalUser: `${isUserInternal(email)}`
        })
    );
    track(pageLoadCompletedEvent());
}

const trackUserLogout = (track, userId, name, tenantId, email) => {
    track(pageInitializedEvent());
    track(
        {
            event: "userLoggedOut",
            user: {
                userId: userId,
                tenantId: tenantId,
                name: name,
                internalUser: `${isUserInternal(email)}`,
                email: email
            }
        }
    );
    track(pageLoadCompletedEvent());
}

const trackPageInfoEvent = (track, pageName, section) => {
    track(
        pageInfoEvent({
            name: pageName,
            canonical: window.location.href,
            section: section
        })
    );
    track(pageLoadCompletedEvent());
}

export {
    trackUserLoginSuccess,
    trackUserLogout,
    trackPageInfoEvent
}