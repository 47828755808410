import React from 'react';
import actions from './actions'
import { connect } from 'react-redux';

export class CovidNewsContainer extends React.Component {
  render() {
    return (
      this.props.active && 
        <div className="row alert">
          <div className="col-lg-12 covid">
            <h4><i className="glyphicon glyphicon-alert"></i>  <span class="covid-warn">Coronavirus (covid-19) freight and supply chain updates</span><i className="glyphicon glyphicon-remove covid-dismiss" onClick={() => this.props.toggleActive()}/></h4>
            <ul>
              <li>Covid–19:&nbsp;<a target="_blank" href="https://www.chrobinson.com/en//en-US/-/media/ChRobinson/documents/STATEMENT%20CONCERNING%20CORONAVIRUS%20COVID19.pdf">Read our latest statement concerning coronavirus (covid–19)</a>.</li>
              <li>Global Client advisories: We're monitoring the impact of of the coronavirus around the world. <a target="_blank" href="https://www.chrobinson.com/en-us/resources/global-trade-resources/client-advisories/">Stay connected</a></li>
              <li>North America client advisory: Get the most up-to-date insights on the North American freight market. <a target="_blank" href="https://www.chrobinson.com/en-us/resources/na-market-update-covid/">Learn more</a></li>
            </ul>
          </div>
          </div>
        );
  }
}
const mapStateToProps = (state) => ({
  active: state.supplyChainHealth.get('covidActive')
});
const mapDispatchToProps = (dispatch) => ({
  toggleActive: () => { dispatch(actions.toggleCovidActive()) }
})
export default connect(mapStateToProps, mapDispatchToProps)(CovidNewsContainer);