import { fromJS } from 'immutable';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import * as types from './actionTypes';

export const initialState = fromJS({
  inTransit: {},
  ocean: {},
  ground: {},
  air: {},
  parcel: {},
  covidActive: window.localStorage.getItem(LOCAL_STORAGE_KEYS.covidDismissed) ? false : true,
  inTransitLoading: true,
  oceanLoading: true,
  airLoading: true,
  groundLoading: true,
  parcelLoading: true,
  trendingLoading: true,
  landingPageConfigLoading: true,
  powerBiLoading: true,
  landingPageConfig: {
    newsAndDisruptions: false,
    news: false,
    disruptions: false,
    supplyChainHealth: false,
    ground: false,
    air: false,
    parcel: false,
    ocean: false,
    parcel: false,
    globalHealth: false,
    trendingShipments: false,
    exceptions: false
  },
  powerBiItems: []
});

function supplyChainHealthReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_LANDING_PAGE_CONFIG_COMPLETED:
      return state
        .set('landingPageConfig', action.payload)
        .set('landingPageConfigLoading', false);
    case types.FETCH_LANDING_PAGE_CONFIG_STARTED:
      return state
        .set('landingPageConfigLoading', true);
    case types.FETCH_HEALTH_STARTED:
      return state
        .set(action.payload.mode.toLowerCase() + 'Loading', true)
    case types.FETCH_TRENDING_SHIPMENTS_STARTED:
      return state
        .set('trendingLoading', true);
    case types.FETCH_HEALTH_IN_TRANSIT_COMPLETED:
      return state
        .set('inTransit', action.payload)
        .set('allLoading', false);
    case types.FETCH_HEALTH_OCEAN_COMPLETED:
      return state
        .set('ocean', action.payload)
        .set('oceanLoading', false);
    case types.FETCH_HEALTH_GROUND_COMPLETED:
      return state
        .set('ground', action.payload)
        .set('groundLoading', false);
    case types.FETCH_HEALTH_AIR_COMPLETED:
      return state
        .set('air', action.payload)
        .set('airLoading', false);
    case types.FETCH_HEALTH_PARCEL_COMPLETED:
      return state
        .set('parcel', action.payload)
        .set('parcelLoading', false);
    case types.FETCH_TRENDING_SHIPMENTS_COMPLETED:
      return state
        .set('trendingShipments', action.payload)
        .set('trendingLoading', false);
    case types.FETCH_HEALTH_IN_TRANSIT_FAILED:
      return state
        .set('allLoading', false);
    case types.FETCH_HEALTH_OCEAN_FAILED:
      return state
        .set('oceanLoading', false);
    case types.FETCH_HEALTH_GROUND_FAILED:
      return state
        .set('groundLoading', false);
    case types.FETCH_HEALTH_AIR_FAILED:
      return state
        .set('airLoading', false);
    case types.FETCH_HEALTH_PARCEL_FAILED:
      return state
        .set('parcelLoading', false);
    case types.FETCH_TRENDING_SHIPMENTS_FAILED:
      return state
        .set('trendingLoading', false);
    case types.TOGGLE_COVID_ACTIVE:
      return state
        .set('covidActive', !state.get('covidActive'));    
    case types.FETCH_POWER_BI_ITEMS_STARTED:
      return state
        .set('powerBiLoading', true);
    case types.FETCH_POWER_BI_ITEMS_COMPLETED:
      return state
        .set('powerBiItems', action.payload)
        .set('powerBiLoading', false);
    case types.FETCH_POWER_BI_ITEMS_FAILED:
      return state
        .set('powerBiLoading', false);
    default:
      return state;
  }
}

export default supplyChainHealthReducer;
