import React from 'react';
export class MaintenanceContainer extends React.Component {
  render() {     
    return (           
        <div className="row alert" >
          <div className="col-lg-12 covid" style={{ marginTop: "-30px" }}>
            <h4><i className="glyphicon glyphicon-alert"></i>  <span class="covid-warn">Planned Maintenance Between 10am - 12 (noon) CST on the following days:</span></h4>
            <ul>
              <li>Sat, Feb 22: Updates will be delayed and Shipment Detail pages may be unavailable.</li>
              <li>Sun, Feb 23: Updates will be delayed.</li>             
            </ul>
          </div>
        </div>
    );
    }
}
export default (MaintenanceContainer);